<template>
  <section class="section container facility-container">
    <div class="text-center heading">
      Departments
    </div>

    <p class="text-center">
      Within our hospital, we've established various departments dedicated to delivering comprehensive care
    </p>

    <div class="row item-grid mt-5 mb-5">
      <div
        v-for="department in departments"
        :key="department.id"
        class="col-md-4 col-sm-6 department-container"
      >
        <div class="d-flex">
          <i 
            class="fa-lg pt-2 department-icon"
            :class="['fas', department.icon]"
          />

          <div>
            <h6 class="department-text">
              {{ department.title }}
            </h6>
    
            <p class="description-text">
              {{ department.description }}
            </p>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
  import { ref } from 'vue';

  export default {
    name: "Facilities Section",

    setup() {
    const departments = ref([
      { 
        id: 1,
        icon: 'fa-lungs',
        title: 'Medicine and TB',
        description: " At the heart of our hospital is the Department of Medicine, where we provide comprehensive care for patients from all walks of life. Our commitment extends to those battling tuberculosis (TB), with all services offered completely free of charge. We stand firm in our resolve to fight TB and provide a brighter future for our community.",
      },
      { 
        id: 2,
        icon: 'fa-tooth',
        title: 'Dentistry and Oral Health',
        description: "In our modern dentistry department, we prioritize oral health as an integral component of overall well-being. Our highly qualified dentists are committed to delivering exceptional dental care, from routine check-ups and cleanings to more complex procedures. We understand that oral health is crucial for our patients' comfort and confidence, and we ensure that our services are accessible to all.",
      },
      { 
        id: 5,
        icon: 'fa-user-nurse',
        title: 'Physiotherapy',
        description: "Recovery and rehabilitation are essential aspects of healthcare, and our physiotherapy department is dedicated to these principles. Our team of physiotherapists provides expert guidance and therapy, helping patients regain their strength, mobility, and independence. Under our supervision, patients receive tailored treatment plans to meet their unique needs.",
      },
      { 
        id: 5,
        icon: 'fa-x-ray',
        title: 'Ultrasound',
        description: "Our state-of-the-art ultrasound department boasts cutting-edge technology and a team of skilled medical professionals. We offer a wide range of ultrasound services, catering to various diagnostic needs. Additionally, we take pride in the presence of our experienced lady doctors, ensuring that our patients receive personalized and compassionate care.",
      },
      { 
        id: 5,
        icon: 'fa-solid fa-baby',
        title: 'Gynecology',
        description: "Women's health is a priority at Shaheen Hospital. Our Gynecology Department is staffed by highly qualified specialists who provide comprehensive care to women of all ages. From routine check-ups to specialized treatments, we are committed to promoting and maintaining the health and well-being of our female patients.",
      },
      { 
        id: 5,
        icon: 'fa-flask',
        title: 'Laboratory',
        description: "Our cost-effective laboratory is equipped to conduct a wide range of diagnostic tests. We prioritize accuracy, affordability, and accessibility in all our laboratory services. We believe that timely and accurate diagnostics are vital for informed medical decisions, and we are committed to delivering on this front",
      },
    ]);

    return { departments };
  }
  }
</script>

<style lang="scss">

.fas {
  color: #A24F00;
}

.description-text {
  font-size: 12px;
  text-align: justify;
}

.item-grid {
  display: flex;
  flex-wrap: wrap;
}

.department-container {
  padding: 1rem 2rem 1rem 1rem;
}

.department-icon {
  margin-right: 1.5rem;
}

.facility-container { 
  margin-bottom: 3rem;
}
</style>