import { initializeApp } from 'firebase/app'
import { getFirestore, collection } from 'firebase/firestore'

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyBJs11HBO-KR4a6eQEMVmHOfRToO20fcbU",
  authDomain: "shaheentbfoundation.firebaseapp.com",
  projectId: "shaheentbfoundation",
  storageBucket: "shaheentbfoundation.appspot.com",
  messagingSenderId: "874049709641",
  appId: "1:874049709641:web:5bdd069785f432e0410198",
  measurementId: "G-H710S2DP23"
})

// used for the databas refs
const db = getFirestore(firebaseApp)
// export const todosRef = collection(db, 'todos')
// export const todosCollection = db.collection('todos');