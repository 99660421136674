<template>
  <div class="">
  <footer class="text-center footer-css">
    Copyright © 2023 Shaheeb T.B Foundation - All Rights Reserved.
  </footer>
</div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: "Footer",

  
}
</script>

<style lang="scss">
  .footer-css {
    background-color: #A24F00;
    color: white;
    padding: 1.5rem 0rem;
    font-size: small;
    font-weight: 300;
  }
</style>