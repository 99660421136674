<template>
  <div id="app">
    <app-navbar/>
    <div id="hero-section">
      <hero-section/>
    </div>
    <div id="about-shaheen-foundation">
      <about-shaheen-foundation/>
    </div>
    <div id="meet-the-team">
      <meet-the-team/>
    </div>
    <div id="facility-section">
      <facility-section/>
    </div>
    <div id="contact-us">
      <contact-us/>
    </div>
    <div id="donation-box">
      <donation-box/>
    </div>
    <div id="app-footer">
      <app-footer/>
    </div>
  </div>
</template>

<script>
  import AppNavbar from './components/app_navbar.vue';
  import HeroSection from './components/hero_section.vue';
  import AboutShaheenFoundation from './components/about_shaheen_foundation_section.vue';
  import FacilitySection from './components/facilities_section.vue';
  import MeetTheTeam from './components/meet_the_team.vue';
  import ContactUs from './components/contact_us_section.vue';
  import DonationBox from './components/donation_box_section.vue';
  import AppFooter from './components/app_footer.vue'

  export default {
    name: 'App',
    components: {
      AppNavbar,
      HeroSection,
      FacilitySection,
      AboutShaheenFoundation,
      MeetTheTeam,
      ContactUs,
      DonationBox,
      AppFooter,
    }
  }
</script>

<style lang="scss">
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
