<template>
  <header class="header">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12 mt-5">
          <div class="text-container">
            <h1 class="heading">
              Together, <br> Let's Defeat TB!
            </h1>
            <p class="p-small">
              Whether you're a patient seeking care or a healthcare provider looking for collaboration, 
              Shaheen T.B Foundation is here to make a difference in the fight against tuberculosis.
              Join us in our commitment to a TB-free future.
            </p>
            <a class="btn-solid-lg mb-5" href="#donation-box">
              Donate to Support
            </a>
          </div>
        </div>
        <img
          src="https://i.imgur.com/1IMChmJ.jpg"
          class="hero-image col-md-6"
        />
      </div>

      <div class="row donation__box">
        <p>
          It's crucial to recognize that TB remains a significant global health concern. In 2020 alone, TB was responsible for approximately 1.5 million lives lost worldwide. While our hospital may focus on a multitude of medical services, we are also deeply committed to addressing the TB challenge, offering hope and healing to those affected by this infectious disease.
          <br/>
          <br/>
          <b>Shaheen T.B Foundation, </b> remains dedicated to our mission, and with your continued support, we aim to expand our services and create a healthier, more vibrant community. Thank you for being a part of our journey toward a better tomorrow. Together, we can make a difference in the lives of those in need.
        </p>
      </div>
    </div>
  </header>
</template>

<script>
  import { ref } from 'vue';
  import { Carousel, Slide, Pagination } from 'vue3-carousel';

  export default {
    name: "Hero Section",

    components: {
      Carousel,
      Slide,
      Pagination,
    },

    setup() {
      const gallaryImages = ref([
        { id: 1, src: 'https://i.imgur.com/1IMChmJ.jpg', alt: 'Doctor with Patient', isActive: true },
        { id: 2, src: 'https://i.imgur.com/mueB345.jpg', alt: 'Doctor with Patient', isActive: false },
        { id: 3, src: 'https://i.imgur.com/9VolMsD.jpg', alt: 'Hall Picture', isActive: false },
      ]);

      return { gallaryImages };
    }
  }
</script>

<style lang="scss">

  .carousel__item {
    background-color: black;
    height: 100%;
    width: 100%;
  }

  .image__for__carousel {
    border-radius: 0.5rem;
  }

  .donation__box {
    background-color: #fcfafb;
    border-radius: 0.5rem;
    margin-top: -0.5rem;
    padding: 2rem;
  }

  .carousel {
    z-index: -999;
  }

  .heading {
    font-size: 48px;
  }
</style>