<template>
  <div class="mb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <img src="https://imgur.com/vrkzfUu.png" alt="img" class="female-dentist-image">
        </div>
        <div class="col-md-6 col-sm-12 p-4">
          <div class="heading">Shaheen T.B Foundation</div>
          <p class="shaheen-paragraph">
            Shaheen T.B. Foundation is a Welfare Organization, working with the passion to eradicate Tuberculosis from Pakistan. It was established by Prof. Dr. Sajid Qayyum as Founder on 24th March 1996 on the International T.B Day with the help of Human Loving People of the AREA. Till then, thousands of patients of T.B. have got treatment and cured with the blessings of Almighty God. At present, Mr Maqbool Ahmed is working as Chairman Shaheen T.B. Foundation and Mr Tariq Gull as the Senior Vice Chairman.
            <br /> <br />
            T.B. is one of oldest and top listed infectious and lethal disease of the world. It occurs mostly (about 95%) in poor people, this is why it is called “Disease of the poor”.
            <br /> <br />
            Previously we had two absolute free TB clinics working. One at Mozang Lahore and the other at Shadman Lahore. Now with the help of humanity loving people of society of have establish an absolute free TB Hospital near Jinnah Hospital Lahore.
            <br /> <br />
            We earnestly invite your support through donations in the form of cash, checks, drafts, or other means, whether for charity, Zakat, Sadaqah, or sacrificial animal skins. Together, we can make a difference in the lives of sick and deserving TB patients and work towards eliminating this disease from our society.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Box",
};
</script>

<style>

.heading { 
  font-size: 32px;
}

.shaheen-paragraph { 
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.container { 
  margin-bottom: 2rem;
}
</style>
