<template>
  <div class="container">
    <div class="meet-the-team text-center">
      <div class="heading">
        Meet the <b> Dedicated </b> Team
      </div>
      <p>
        Discover the dedicated minds and compassionate hearts behind the Shaheen T.B. Foundation's mission to eradicate Tuberculosis in Pakistan, founded by Prof. Dr. Sajid Qayyum on March 24, 1996. Led by Mr. Maqbool Ahmed, our team is committed to making a difference in the lives of TB patients.
      </p>
  
      <div class="row">
        <div v-for="member in team" class="col-md-3 col-sm-6 member-box">
          <img :src="member.image" class="person--images">
          <div class="member-name-text">
            {{ member.name }}
          </div>
          <div class="member-position-text">
            {{ member.position }}
          </div>
          <div class="member-position-text">
            {{ member.phone }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: "Meet the Team Section",
  setup() {
    const team = ref([
      {
        id: 0,
        image: 'https://i.imgur.com/6kkEcot.png',
        name: 'Prof Dr. Sajid Qayyum',
        position: 'Founder of Shaheen T.B Foundation',
        phone: '+(92) 300 8898810',
      },
      {
        id: 1,
        image: 'https://i.imgur.com/z7M6r0M.png',
        name: 'Mr Maqbool Ahmed',
        position: 'Chairman of Shaheen T.B Foundation',
        phone: '+(92) 333 4203465',
      },
      {
        id: 2,
        image: 'https://i.imgur.com/jwNYTcR.png',
        name: 'Mr Tariq Gull',
        position: 'Senior Vice Chairman of Shaheen T.B Foundation',
        phone: '+(92) 344 4121323',
      },
      {
        id: 3,
        image: 'https://i.imgur.com/c2oowb4.png',
        name: 'Mr Ameer Shahzad',
        position: 'General Secretary of Shaheen T.B Foundation',
        phone: '+(92) 333 4301263',
      },
    ]);

    return { team };
  }
}
</script>

<style lang="scss">

.person--images {
  width: 100%;
}

.meet-the-team {
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
}

.member-name-text {
  font-weight: 500;
  color: #A24F00;
}

.member-position-text {
  font-size: 12px;
}

h6 {
  margin: 0;
}
</style>