<template>
  <div class="color-box container">
    <div class="contact-right">
      <h4 class="mb-4">
        Contact Us
      </h4>
      <div
        v-for="(contact, index) in contacts"
        class="d-flex align-self-center"
        :class="{ 'mb-4': index < 2 }"
      >
        <i 
          class="fa pt-2 contact-icon"
          :class="['fas', contact.icon]"
        />

        <div class="contact-detail-box pl-3">
          <div class="contact-detail-heading">
            {{ contact.heading }}
          </div>
          <div class="contact-detail-text">
            {{ contact.detail }}
          </div>
        </div>
      </div>
    </div>
    <div class="contact-left">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3402.8072034772163!2d74.29788769999999!3d31.4744893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391903935661131d%3A0x74873a79f1680682!2sShaheen%20Hospital%20under%20Shaheen%20TB%20Foundaiton!5e0!3m2!1sen!2sde!4v1699653795485!5m2!1sen!2sde"
        width="100%" height="650" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"/>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: "Contact Us Section",

  setup() {
    const contacts = ref([
      {
        id: 0,
        icon: 'fa-phone',
        heading: 'Mobile Number',
        detail: '+92 300 8898810',
      },
      {
        id: 1,
        icon: 'fa-envelope',
        heading: 'Email Address',
        detail: 'foundationshaheen@gmail.com',
      },
      {
        id: 2,
        icon: 'fa-map',
        heading: 'Address',
        detail: 'A-251, near Jinah Hospital, Board of Revenue Housing Society BOR Society, Lahore',
      },
    ]);

    return { contacts };
  }
}
</script>

<style lang="scss">
  .color-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 9rem;
  }
  .contact-left {
    width: 100%;
    // height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    border-radius: 8px;
  }

  .contact-right h4 {
    font-weight: lighter;
    color: white;
    margin-bottom: 2rem;
  }

  .contact-icon { 
    color: white;
    margin: 0rem 1rem 0rem 0rem;
  }

  .contact-detail-box { 
    // border-left: white 1px solid;
    padding-left: 1rem;
  }

  .contact-detail-heading { 
    font-size: 12px;
    font-weight: bold;
  }

  .contact-detail-text { 
    line-height: normal;
    font-size: 12px;
  }
</style>