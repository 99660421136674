<template>
  <nav 
    class="navbar navbar-expand-lg fixed-top navbar-light"
    aria-label="Main navigation"
  >
    <div class="container">
      <a 
        href="#"
        class="navbar-brand logo-text"
      >
        <img 
          src="https://i.imgur.com/B7nngV0.png"
          class="navbar-brand logo"
        />
        Shaheen T.B Foundation
      </a>
      <div class="navbar-collapse offcanvas-collapse">
        <ul class="navbar-nav ms-auto navbar-nav-scroll">
          <li
            v-for="item in navItems"
            :key="item.id"
            class="nav-item"
          >
            <a 
              :href="item.link"
              class="nav-link"
              :class="{'active': item.isActive}"
            >
              {{ item.label }}
            </a>
          </li>
        </ul>
      </div>
      <span class="nav-item">
        <a 
          class="btn-solid-sm"
          href="#donation-box"
        >
          Donate
        </a>
      </span>
    </div>
  </nav>
</template>

<script>
import { ref } from 'vue';

export default {
  name: "NavBar",

  setup() {
    const navItems = ref([
      { id: 1, label: 'Home', link: '/#app', isActive: true },
      { id: 2, label: 'About', link: '/#about-shaheen-foundation', isActive: true },
      { id: 3, label: 'Services', link: '/#facility-section', isActive: true },
      { id: 4, label: 'Contact', link: '/#contact-us', isActive: true }
    ]);

    return { navItems };
  }
}
</script>
